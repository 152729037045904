.container__rules {
    position: relative; /* Position relative pour que .rules puisse se positionner à l'intérieur */
    width: 100%; /* S'étend sur toute la largeur */
    max-width: 900px; /* (Optionnel) Limite la largeur sur les écrans larges */
    margin: 0 auto; /* Centre le conteneur horizontalement */
  }
  
  .container__rules img {
    width: 80%;
    height: auto; /* Garde les proportions */
    border-radius: 10px; /* Coins arrondis */
  }
  
  .rules {
    position: absolute; /* Se positionne par rapport à .container__rules */
    top: 50%; /* Centre verticalement */
    left: 50%; /* Centre horizontalement */
    transform: translate(-50%, -50%); /* Centre parfaitement */
    background-color: rgba(49, 50, 52, 0.9); /* Fond légèrement transparent */
    border-radius: 10px; /* Coins arrondis */
    padding: 20px; /* Espace interne pour le contenu */
    text-align: center;
    width: 70%; /* Largeur du bloc par rapport au conteneur */
    max-width: 400px; /* Largeur maximale pour éviter qu'il ne devienne trop large */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Ombre élégante */
  }

  .align-center{
    align-items: center;
  }
  
  .rules__title {
    color: white;
    font-size: 18px; /* Taille légèrement augmentée */
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .rules__p {
    color: #9c9fa8;
    font-size: 14px;
  }

  .golf-button{
    width: 200px;
    margin: auto;
    margin-bottom: 20px;
  }
  

/* Responsive : ajustements pour les petits écrans */
@media screen and (max-width: 768px) {
  .rules {
    width: 90%; /* Prend presque toute la largeur sur mobile */
    padding: 15px; /* Réduit légèrement le padding */
    top: 45%;
  }

  .rules__title {
    font-size: 16px; /* Réduction de la taille du titre */
  }

  .rules__p {
    font-size: 12px; /* Réduction de la taille du texte */
  }

  .container__rules img {
    width: 100%;

}
}



  /* Responsive : ajustements pour les petits écrans */
  @media screen and (max-width: 560px) {
    .rules {
      width: 50%; /* Prend presque toute la largeur sur mobile */
      padding: 15px; /* Réduit légèrement le padding */
      top: 47%;
    }
  
    
  }
  
