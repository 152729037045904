@media (max-width: 768px) {
    /* 🔹 Conteneur global qui regroupe la nav et la bannière */
    .nav-banner-container {
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 1000;
      display: flex;
      flex-direction: column-reverse; /* ✅ Bannière en bas, nav juste au-dessus */
    }
  
    /* 🔹 Bannière en bas */
    .fixed-banner {
      position: relative;
      width: 100%;
      max-height: 100px; /* ✅ Elle ne dépasse jamais cette hauteur */
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden; /* ✅ Pas de débordement */
      transition: height 0.2s ease-in-out; /* 🔥 Transition fluide */
    }
  
    .fixed-banner img {
      width: 100%;
      height: auto;
      object-fit: contain; /* ✅ Affichage complet */
    }
  
    /* 🔹 Navigation fixée au-dessus de la bannière */
    .nav {
      position: absolute;
      bottom: 60px !important; /* 🔥 S'aligne dynamiquement sur la bannière */
      width: 100%;
      height: 69px !important;
      background: rgba(51, 51, 51, 0.9); /* ✅ Fond semi-transparent */
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
  
  /* ✅ Sur PC, la bannière devient un footer normal, DÉTACHÉ de la nav */
  @media (min-width: 769px) {
    .nav-banner-container {
      position: static; /* ✅ Plus de position fixe */
    }
  
    /* 🔹 Footer normal, en bas de la page uniquement si le contenu est court */
    .fixed-banner {
      position: relative;
      width: 100%;
      max-height: 120px;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      margin-top: auto; /* ✅ Pousse le footer vers le bas si le contenu est court */
    }
  
    .fixed-banner img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  
    /* 🔹 Navigation reprend une position normale */
    .nav {
      position: relative;
      width: 100%;
      height: auto;
      background: #333;
    }
  
    /* 🔹 Assure que le contenu pousse le footer vers le bas UNIQUEMENT si la page est courte */
    html, body {
      height: 100%;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
    }
  
    .main-content {
      flex-grow: 1; /* ✅ Fait en sorte que le footer soit en bas */
    }
  }
  