.chatbot-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0.5rem 1rem 0.5rem;
    font-family: 'Segoe UI', sans-serif;
    color: white;
    position: relative;
  }

.explication-button,.mic-button{
  position: absolute;
}

/* ✅ Bouton "Explication" sous l'input */
.explication-button {
  position: absolute;
  left: 8px;
  top:100px;
  background: #444;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 7px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-size: 0.7rem;
}

.explication-button.active {
  background: linear-gradient(90deg, #a4e66f, rgb(2, 196, 2));
  color:#292828; ;
}

.explication-button.active:hover {
  color: #2b2727;
}





  
  .chat-header {
    text-align: center;
    position: relative;
  }
  
  .chat-header h2 {
    font-size: 1.8rem;
    margin: 0;
    text-shadow: 0 2px 4px rgba(0,0,0,0.2);
  }
  
  .status-indicator {
    width: 12px;
    height: 12px;
    background: #4CAF50;
    border-radius: 50%;
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .status-indicator.pulsating {
    animation: pulse 1.5s infinite;
  }
  
  .chat-window {
    width: 100%;
    max-width: 861px;
    height: 58vh;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    padding: 1.5rem;
    margin-bottom: 1rem;
    box-shadow: 0 8px 32px rgba(0,0,0,0.1);
    overflow-y: auto;
  }
  
  .input-container {
    width: 100%;
    position: relative;
    max-width: 600px;
    display: flex;
    gap: 0.8rem;
    align-items: center;
  }
  
  .mic-button {
    background: rgb(100, 100, 100, 0.7); /* Légèrement plus transparent pour plus de discrétion */
    border: none;
    display: flex;
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 5px;
    transition: all 0.2s ease-in-out;
    padding: 0px 13px;
}

.mic-button:hover {
    background: rgb(120, 120, 120, 0.9); /* Légèrement plus visible au survol */
    transform: scale(1.1); /* Effet subtil d'agrandissement */
}
  
  .mic-button.active {
    background: #ff4444;
    transform: scale(1.1);
  }
  
  .sound-waves {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    animation: sonar 1.5s infinite;
  }
  
 

  textarea {
    width: 100%;
    min-height: 70px;
    max-height: 200px; /* Limite la hauteur */
    overflow-y: auto; /* Active la barre de défilement verticale si nécessaire */
    padding: 1rem 56px 2.5rem 10px;
    border: none;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.9);
    font-size: 1rem;
    transition: all 0.3s;
    resize: none; /* Empêche l'utilisateur de redimensionner */
}


  



  
  input.recording {
    box-shadow: 0 0 15px rgba(255, 68, 68, 0.3);
  }
  
  .send-button {
    background: #2a2a2b;;
    position: absolute;
    right: 5px;
    top: 55px;
    color: white;
    border: none;
    width: 22px;
    height: 35px;
    border-radius: 25%;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s;
  }
  
  .send-button.loading {
    background: #666;
  }
  
  .send-button:hover:not(:disabled) {
    transform: scale(1.05);
    box-shadow: 0 4px 15px rgba(33, 150, 243, 0.4);
  }
  
  .spinner {
    width: 24px;
    height: 24px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-top-color: white;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  .browser-warning {
    margin-top: 1rem;
    padding: 0.8rem;
    background: rgba(255, 204, 0, 0.15);
    border-radius: 8px;
    font-size: 0.9rem;
  }
  
  @keyframes pulse {
    0% { box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.4); }
    70% { box-shadow: 0 0 0 15px rgba(76, 175, 80, 0); }
    100% { box-shadow: 0 0 0 0 rgba(76, 175, 80, 0); }
  }
  
  @keyframes sonar {
    0% { opacity: 1; transform: scale(1); }
    100% { opacity: 0; transform: scale(1.8); }
  }
  
  @keyframes spin {
    to { transform: rotate(360deg); }
  }
  
  /* Styles existants pour les messages et animations */
  .message {
    margin: 20px 0;
    opacity: 0;
    transform: translateY(20px);
    animation: messageAppear 0.4s ease-out forwards;
    
  }
  
  @keyframes messageAppear {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .parent-container {
    text-align: right; /* Aligne le contenu à droite */
}

.user-message .message-text {
    display: inline-block; /* Nécessaire pour que le bloc respecte l'alignement */
    background: linear-gradient(45deg, #4CAF50, #45a049);
    color: white;
    border-radius: 20px 20px 5px 20px;
    padding: 15px;
}



  .bot-message .message-text {
    background: linear-gradient(45deg, #ffffff, #f8f9fa);
    color: #333;
    border-radius: 20px 20px 20px 5px;
    padding: 15px;
  }
  
  .typing-indicator {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
  }



  




  .message {
    display: flex; /* Pour gérer les alignements */
}

.user-message {
    justify-content: flex-end/* Aligne les messages utilisateur à gauche */
}

.bot-message {
    justify-content: flex-start;
}

.message-text {
    display: inline-block; /* Permet un bon alignement des blocs */
    word-wrap: break-word; /* Évite le débordement */
    padding: 15px;
    border-radius: 20px; /* Coins arrondis */
}

.user-message .message-text {
    background: linear-gradient(45deg, #4CAF50, #45a049); /* Couleur pour utilisateur */
    color: white;
    border-radius: 20px 20px 5px 20px;
}

.bot-message .message-text {
    background: linear-gradient(45deg, #ffffff, #f8f9fa); /* Couleur pour bot */
    color: #333;
    border-radius: 20px 20px 20px 5px;
}




/* Ajouter ces styles */
.camera-button.active {
  background: #4CAF50;
  animation: pulse 1.5s infinite;
}

.photo-preview {
  position: relative;
  margin: 10px 0;
  border: 2px solid #4CAF50;
  border-radius: 8px;
  padding: 5px;
}

.photo-preview::after {
  content: "✓ Photo prête";
  position: absolute;
  bottom: -25px;
  left: 0;
  color: #4CAF50;
  font-weight: bold;
  font-size: 0.8em;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.capture-effect {
  animation: flash 0.3s;
}

@keyframes flash {
  0% { filter: brightness(100%); }
  50% { filter: brightness(150%); }
  100% { filter: brightness(100%); }
}

.camera-modal video {
  max-width: 100%;
  border-radius: 10px;
  transform: scaleX(-1); /* Miroir pour l'UX */
}

@media (orientation: portrait) {
  .camera-modal {
    width: 90vw;
    height: 70vh;
  }
  
  .camera-modal video {
    object-fit: cover;
  }
}




@media screen and (max-width: 425px) {
  .message-text {
    max-width: 100%; 
  }
} 



/* 🌈 Barre indicatrice sous le champ texte */
.image-ready-bar {
  position: absolute;
  bottom: -1px;
  left: 10px;
  width: calc(100% - 20px);
  height: 3px;
  background: linear-gradient(90deg, #00c853, #00bcd4);
  border-radius: 3px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

/* 🎯 Bouton caméra avec changement en croix */
.camera-button {  
  background: #444;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1em;
  position: absolute;
  left: 171px;
  top: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease-in-out;
  padding: 0px 13px
}

.camera-button.has-image {
  background: #7b2a2a;
  padding: 4px 13px
}

.camera-button:hover {
  transform: scale(1.1);
}



.camera-icon:hover {
  color: black;
}



.spinner {
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top: 2px solid white;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



@media screen and (max-width: 768px) {
  .input-container {
    width: 96%;
    position: fixed;
    bottom: 174px;
  }
}






/* 2. Appareils à hauteur moyenne */
@media screen and (max-width: 768px) and (min-height: 680px) and (max-height: 950px) {
  .chat-window {
    max-height: calc(72vh - 175px) !important;
  }
}

@media screen and (max-width: 768px) and (min-height: 680px) and (max-height: 745px) {
  .chat-window {
    max-height: calc(69vh - 167px) !important;
  }
}

@media screen and (max-width: 768px) and (min-height: 560px) and (max-height: 680px) {
  .chat-window {
    max-height: calc(64vh - 167px) !important;
  }
}










/* Styles pour les messages avec image */
.message-with-image {
  padding: 10px !important;
  max-width: 80% !important;
}

/* Conteneur d'image avec ratio préservé */
.image-wrapper {
  position: relative;
  width: 100%;
  max-height: 250px;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 8px;
}

/* Style de l'image */
.chat-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  max-height: 250px;
  transition: transform 0.3s ease, filter 0.2s ease;
  display: block;
}

/* Animation au survol */
.image-wrapper:hover .chat-image {
  transform: scale(1.02);
  filter: brightness(1.05);
}

/* Adaptation responsive */
@media screen and (max-width: 768px) {
  .message-with-image {
    max-width: 90% !important;
  }
  
  .image-wrapper {
    max-height: 200px;
  }
  
  .chat-image {
    max-height: 200px;
  }
}

@media screen and (max-width: 480px) {
  .message-with-image {
    max-width: 95% !important;
  }
  
  .image-wrapper {
    max-height: 180px;
  }
  
  .chat-image {
    max-height: 180px;
  }
}












