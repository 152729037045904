body{
    background-color: #1a1d1f;
}


.main-component{
  padding: 30px 0 30px 0;
  width: 95%; /* Largeur de 95% de l'écran */
  max-width: 1200px; /* (Optionnel) Limite maximale pour éviter un bloc trop large */
  margin: 0 auto; /* Centre horizontalement */

} 

button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: rgb(78, 78, 78);
  text-decoration: none;
  background: linear-gradient(90deg, #a4e66f, green);
  border: none;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

button::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.4s ease, height 0.4s ease, opacity 0.6s ease;
  z-index: -1;
}

button:hover {
  background: linear-gradient(90deg, #a4e66f, rgb(2, 196, 2));
  transform: translateY(-2px) scale(1.05);
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.3);
  color: rgb(26, 26, 26);
}

button:hover::before {
  width: 300%;
  height: 300%;
  opacity: 0;
}

button:active {
  transform: translateY(0px) scale(0.96);
}

button:focus {
  outline: none;
  color: rgb(26, 26, 26);
}

button span {
  position: relative;
  z-index: 2;
}

button::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: skewX(-45deg);
  transition: left 0.5s ease;
  z-index: 0;
}

button:hover::after {
  left: 100%;
}

@media screen and (max-width: 768px) {
  button {
    font-size: 16px; 
  }

}







* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

  }

  p{
    font-size: 12px;
  }
  
  html, body {
    width: 100%;
    height: 100%;
  }
  
  body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif; 
  }
  