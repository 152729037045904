@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .routes-container {
    opacity: 1; /* Garde l'opacité à 1 après l'animation */
  }
  
  .routes-container.re-animate {
    animation: fadeInUp 0.3s ease-out;
  }
  