.main__container{
    width: 80%;
    background-color: rgb(54, 54, 54);
    margin: auto;
    padding:30px 15px 30px 15px;
}

.container__timer{
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #2a2d2f;
    width: 100%;
    h2{
        color: white;
        font-size: 20px;
    }
    p{
        font-size: 35px;
    }
}


.container__rules{
    margin: 20px auto 20px auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
   
    background-color: #2a2d2f;
    line-height: 20px;
    color: white;
  &__name{
    padding-bottom: 20px;
    font-size: 25px;
    color: #a4e66f;
    line-height: 26px;
    }
}

.container__timer,.container__rules{
  border-radius: 10px;
}




.container__rules ul {
  list-style-position: outside; /* Place les puces à l'extérieur du texte */
  padding-left: 20px; /* Crée de l'espace pour aligner les puces */
  margin-top: 10px;
}

.container__rules li {
  margin-bottom: 10px; /* Espacement entre chaque élément de liste */
}

.container__timer__main{
  display: flex; 
  gap: 2rem;
  max-width: 900px;
  margin: auto;

}



  // Mobile 
  @media screen and (max-width: 535px) { 
    .container__timer__main{
      display: flex;
      flex-direction: column;
      gap: 0rem;
    }
  }

  
 

 
  