.header {
  position: relative;
  img {
    height:320px !important;
    display: block;
    width: 100%;
    object-fit: cover;
    object-position: bottom;
  }

  .flex {
    display: flex;
  }
  .flex-center {
    align-items: center;
    justify-content: center;
  }

  .timer-ico {
    margin-right: 10px;
  }

  .pinDay {
    position: absolute;
    width: 185px;
    top: 20px;
    left: 20px;
    background-color: #313234;
    border-radius: 19px;
    color: white;
    padding: 12px;

    &__heure {
      font-weight: bold;
      color: white;
    }
    &__jour {
      font-weight: 300;
      color: #c6c6ce;
    }
  }

  h1 {
    width: 351px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
   color: white;
   text-shadow:
       3px 3px 0 #000,
     -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
       1px 1px 0 #000;

  }
}

/* Navigation principale */
.nav {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: #313234;
  padding: 15px 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 2px solid #444;
  z-index: 1000;
}

.nav li {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav__link {
  display: flex;
  flex-direction: column; /* Icône au-dessus du texte */
  justify-content: center;
  align-items: center;
  gap: 5px; /* Espacement entre icône et texte */
  text-decoration: none;
  color: rgb(207, 207, 207); /* Couleur du texte */
  font-weight: bold;
  font-size: 11px; /* Taille de texte standard */
  text-align: center;
  padding: 10px; /* Zone cliquable plus grande */
  border-radius: 10px; /* Coins arrondis */
  transition: transform 0.3s ease, background-color 0.3s ease, color 0.3s ease;
}

.nav__link:hover {
  transform: scale(1.1); /* Légère augmentation au survol */
  background-color: rgba(255, 255, 255, 0.1); /* Fond léger au hover */
  color: #a4e66f; /* Texte en vert au survol */
}

.nav__icon {
  font-size: 24px;
  color: inherit; /* Hérite la couleur du lien */
}

/* État actif */
.nav li.active .nav__link {
  color: #a4e66f; /* Couleur verte pour le lien actif */
  border-bottom: 2px solid #a4e66f;
  transform: scale(1.1);
  background-color: rgba(255, 255, 255, 0.1);
}

/* Responsive uniquement pour mobile */
@media screen and (max-width: 768px) {
  .header img {
    height: 83px !important;
  }

  .nav {
    flex-direction: row; /* Barre horizontale */
    justify-content: space-around; /* Espacement égal des éléments */
    padding: 3px 0;
    position: fixed;
    bottom: 73px;
  }

  .nav__link {
    font-size: 11px; /* Texte plus petit */
    padding: 8px; /* Zone tactile ajustée */
  }

  .nav__icon {
    font-size: 20px; /* Taille réduite des icônes sur mobile */
  }
}

.popup-container {
  position: fixed;
  top: 80px;
  left: 20px;
  background-color: #313234;
  color: white;
  padding: 16px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  z-index: 1001; /* Toujours au-dessus */
}


.popup-container.show {
  transform: translateX(0);
}












/* Affichage visible */
.timer-popup.show {
  display: block;
  opacity: 1;
}

.timer-popup.show {
  display: block;
}





/* Pop-ups individuelles (mobile) */



/* Pop-ups individuelles (mobile) */
.timer-popup {
  position: fixed;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  display: none;
  z-index: 1100; /* Assure qu'elles sont tout en haut */
}



/* Animation du texte */
.popup-time {
  transition: transform 0.3s ease-in-out;
}

.timer-popup.expanded .popup-time {
  transform: translateY(-10px);
}

/* Animation des boutons */
.popup-buttons {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.popup-buttons.show {
  opacity: 1;
}



/* Minuteurs en bas (mobile) */
.mobile-timer-container {
  position: absolute;
  top: 81px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  padding: 10px;
  border-radius: 10px 10px 0 0;
  z-index: 1002;
  gap: 56px;
}

/* Style des minuteurs */
.mobile-timer {
  color: white;
  font-size: 11px;
  cursor: pointer;
  padding: 7px 6px;
  background: #2c2d2f;
  border-radius: 8px;
  text-align: center;
  overflow: hidden;
  transition: all 0.25s ease-in-out; /* Animation rapide */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 140px;
  height: 32px; /* Taille de base */
}

/* Agrandissement du timer sélectionné */
.mobile-timer.expanded {
  background: white;
  color: black;
  padding: 16px;
  border-radius: 12px;
  width: 220px;
  height: 150px;
}

/* Animation du texte */
.timer-text {
  transition: transform 0.25s ease-in-out;
}

.mobile-timer.expanded .timer-text {
  transform: translateY(-5px);
}

/* Animation des boutons */
.timer-buttons {
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
  margin-top: 10px;
}

.mobile-timer.expanded .timer-buttons {
  opacity: 1;
}

/* Boutons Pause et Recommencer côte à côte */
.timer-buttons .btn-row {
  display: flex;
  gap: 5px;
  width: 100%;
  justify-content: center;
}

/* Bouton unique */
.timer-buttons button {
  flex: 1;
  padding: 8px;
  font-size: 12px;
  border-radius: 6px;
  border: none;
  background: linear-gradient(145deg, #4caf50, #388e3c);
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

/* Bouton Arrêter en dessous, occupant toute la largeur */
.timer-buttons .btn-stop {
  width: 100%;
  background: #d32f2f;
}


.mobile-timer.dark-mode {
  background: rgba(30, 30, 30, 0.95) !important; /* 🔥 Mode sombre activé */
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.mobile-timer.dark-mode .timer-text {
  color: white !important;
}

.mobile-timer.dark-mode .timer-buttons button {
  background: #444 !important;
  color: white !important;
}

.mobile-timer.dark-mode .btn-stop {
  background: #ff5555 !important;
  color: white;
}




// profile 

.profile-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: not-allowed;
  opacity: 0.6;
  font-size: 24px;
}

.profile-btn:hover {
  opacity: 1;
}

.profile-icon {
  color: #333;
}

@media (max-width: 768px) {
  .profile-btn {
    top: 5px;
    right: 5px;
    font-size: 20px;
  }
}



/* Minuteurs en bas (mobile) */
.mobile-timer-container {
  position: absolute;
  top: 81px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around; /* Distribution égale par défaut */
  padding: 10px;
  border-radius: 10px 10px 0 0;
  z-index: 1002;
  gap: 56px;
}

/* Ajout d'une classe pour quand il n'y a qu'un seul timer visible */
.mobile-timer-container.single-timer {
  justify-content: flex-start; /* Alignement à gauche */
  padding-left: 20px; /* Espace à gauche */
}

/* Animation de fermeture */
.mobile-timer.closing {
  animation: closeTimer 0.3s forwards;
}

@keyframes closeTimer {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.8);
  }
}